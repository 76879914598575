.Feature-Section {
  flex-wrap: nowrap !important;
}
.arrowMargin {
  width: 20px;
}
.makeStyles-flex-63 {
  width: 300px !important;
}
.Before-Prod ul li::marker {
  color: #e5e5e5;
}
.After-Prod ul li::marker {
  color: #f88216;
}
