.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.FooterLinks {
  color: #ffffff90;
}
@media screen and (max-width: 600px) {
  .Feature-Head {
    justify-content: unset !important;
  }
  .KPO-container {
    align-items: flex-start !important;
  }
  .Solution-container .MuiGrid-item > .MuiGrid-item p,
  .Align-Left {
    text-align: left !important;
  }
  .Main-Img {
    width: 300px;
    margin: auto;
  }
  .Main-section-1,
  .Main-Section-Head,
  .Prod-Head,
  .KPO-Subhead,
  .CollectEazeFeatures ul li,
  .Review-sub {
    text-align: left !important;
    align-items: unset !important;
  }
  .Feature-section-box {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .CollectEazeFeatures .Feature-Scroll {
    max-width: 350px;
    overflow: scroll;
    justify-content: unset !important;
  }

  .CollectEazeFeatures .Feature-Scroll::-webkit-scrollbar {
    height: 3px;
    background-color: #f5f5f5;
  }
  .CollectEazeFeatures .Feature-Scroll::-webkit-scrollbar-thumb {
    width: 2px;
    background: #f88216;
    border-radius: 2px;
  }
  .Feature-Name {
    min-width: 200px;
  }
  .CollectEazeFeatures .Feature-text {
    font-size: 14px;
    margin-left: 0;
  }
  .CollectEazeFeatures .Feature-Name {
    padding: 10px 0px;
  }
  .CollectEazeFeatures .Feature-container {
    max-width: 100%;
  }
  .CollectEazeFeatures .Feature-Buttons {
    flex-direction: unset !important;
  }
  .Collecteaze-Footer .Footer-Content {
    gap: 20px;
  }
  .Collecteaze-Footer .Footer-Content > div {
    max-width: fit-content !important;
  }
  .Collecteaze-Footer .Footer-last {
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 700px) {
  .Main-Img {
    width: 400px;
  }
  .Collecteaze-Head .Main-section-1 {
    padding-left: 50px;
  }
  .Collecteaze-Head .Main-Container {
    min-height: 60vh;
  }
  .Collecteaze-Solution,
  .Collecteaze-Prod,
  .Collecteaze-parameter,
  .CollectEazeFeatures,
  .Collecteaze-Why,
  .Collecteaze-ClientReviews,
  .Collecteaze-Footer {
    padding: 50px !important;
  }
  .Collecteaze-Solution .Solution-container {
    flex-direction: row;
  }
  .Collecteaze-Solution .Solution-img {
    height: 400px;
  }
  .CollectEazeFeatures .Feature-Section {
    flex-direction: unset !important;
    align-items: unset !important;
  }
  .CollectEazeFeatures .Feature-Scroll {
    flex-direction: column;
    justify-content: unset !important;
  }
  .CollectEazeFeatures .Feature-Buttons {
    flex-direction: unset !important;
  }
  .Collecteaze-Why .Why-us-container {
    flex-wrap: nowrap;
    gap: 10px;
  }
  .Collecteaze-ClientReviews .Review-container {
    flex-wrap: nowrap;
  }
  .FooterLinks {
    display: inline-block;
  }
  .Collecteaze-Footer .Footer-Content {
    display: block !important;
  }
  .FooterLinks {
    width: 200px !important;
  }
}
@media screen and (max-width: 800px) and (min-width: 650px) {
  .Collecteaze-parameter .Parameter-container {
    gap: 10px;
  }
  .Collecteaze-parameter .Feature-section-box {
    width: 49%;
  }
  .Collecteaze-Footer .Footer-Content {
    justify-content: space-around;
  }
  .ProdSec {
    flex-direction: column;
  }
}
.ProdImg {
  width: 540px;
}

